import React, { useState, useEffect } from "react";
import posthog from "posthog-js";
import "../App.css";

function App() {
  const [waitlistView, setWaitlistView] = useState(false);

  // mac, windows, linux, mobile
  const [downloadButtonText, setDownloadButtonText] = useState("");
  const [platform, setPlatform] = useState("");
  useEffect(() => {
    const platform = navigator.userAgent.toLowerCase();
    if (platform.includes("macos") || platform.includes("macintosh")) {
      setPlatform("mac");
      setDownloadButtonText("Download for Mac");
    } else if (platform.includes("windows")) {
      setPlatform("windows");
      setDownloadButtonText("Join Waitlist for Windows");
    } else if (platform.includes("linux")) {
      setPlatform("linux");
      setDownloadButtonText("Join Waitlist for Linux");
    } else if (
      platform.includes("android") ||
      platform.includes("ios") ||
      platform.includes("iphone") ||
      platform.includes("ipad")
    ) {
      setPlatform("mobile");
      setDownloadButtonText("Join Waitlist for Mobile");
    } else {
      setPlatform(platform);
      setDownloadButtonText("Join Waitlist");
    }
  }, []);

  function showWaitlist() {
    setWaitlistView(true);
    posthog.capture(`WAITLIST: ${platform}`);
  }

  async function prepareDownload(platform) {
    if (platform === "mac") {
      setDownloadButtonText("Downloading...");
      try {
        posthog.capture("DOWNLOAD: MAC");
        window.location.href =
          "https://pumpkin-executables.pumpkin-executables.workers.dev/download/pumpkin.dmg";

        setDownloadButtonText("Thank you for downloading!");
        setTimeout(() => setDownloadButtonText("Download for Mac"), 5000);
      } catch (error) {
        console.error("Download failed:", error);
        showWaitlist();
      }
    } else {
      showWaitlist();
    }
  }

  return (
    <div className="min-h-screen">
      {waitlistView ? (
        <div className="flex flex-col items-center justify-center h-screen absolute inset-0 bg-[linear-gradient(135deg,#FAFAFA,#F0F0F0,#EADFD6,#D9EFF7,#F3D5C0,#F0F0F0)] animate-gradient-wave">
          <iframe
            src="https://tally.so/embed/w7J6p2?&transparentBackground=1&dynamicHeight=1"
            className="w-full h-[529px]"
            title="Pumpkin Waitlist"
          ></iframe>
        </div>
      ) : (
        <div className="min-h-screen bg-[linear-gradient(135deg,#FAFAFA,#F0F0F0,#EADFD6,#D9EFF7,#F3D5C0,#F0F0F0)] animate-gradient-wave">
          <div className="w-full min-h-screen gap-8 flex flex-col items-center justify-center py-8">
            <div className="flex flex-col justify-start items-center">
              {/* Larger Title on Mobile */}
              <div className="flex flex-row items-center justify-center mb-3 md:mb-4">
                <img
                  src="/adobe2.svg"
                  alt="Pumpkin"
                  className="hidden lg:block w-44 h-44 mb-5 -mr-6 -ml-10"
                />
                <h1 className="relative text-7xl md:text-8xl tracking-tighter font-medium drop-shadow-sm text-gray-900">
                  Pumpkin
                </h1>
              </div>

              {/* Adjusted Tagline Spacing */}
              <div className="group lg:-mt-10">
                <p className="text-xl md:text-2xl font-light leading-snug text-gray-700 text-center px-6">
                  A web browser built for{" "}
                  <span className="relative text-gray-700 transition-colors duration-300 group-hover:text-[#52ADC9] after:content-[''] after:absolute after:left-0 after:bottom-[-3px] after:w-0 after:h-[3px] after:bg-[#52ADC9] after:opacity-80 after:transition-all after:duration-300 group-hover:after:w-full">
                    <span className="font-light">human</span>
                  </span>{" "}
                  and{" "}
                  <span className="relative text-gray-700 transition-colors duration-300 group-hover:text-[#C96F53] after:content-[''] after:absolute after:left-0 after:bottom-[-3px] after:w-0 after:h-[3px] after:bg-[#C96F53] after:opacity-80 after:transition-all after:duration-300 group-hover:after:w-full">
                    <span className="font-light">AI</span>
                  </span>{" "}
                  collaboration
                </p>
              </div>

              {/* Adjusted Button Spacing */}
              <button
                disabled={
                  downloadButtonText === "Downloading..." ||
                  downloadButtonText === "Thank you for downloading!"
                }
                onClick={() => prepareDownload(platform)}
                className="relative mt-5 md:mt-8 px-8 py-4 text-lg font-medium text-gray-900 bg-[#F5F5F5] rounded-none border border-transparent shadow-none transition-all group"
              >
                <span className="relative z-10 transition-colors duration-500 group-hover:text-[#52ADC9]">
                  {downloadButtonText}
                </span>

                {/* Outline Animation */}
                <div className="absolute inset-0 pointer-events-none">
                  <div className="absolute inset-x-0 bottom-0 h-[2px] bg-[#52ADC9] origin-left scale-x-0 transition-transform duration-200 group-hover:scale-x-100"></div>
                  <div className="absolute inset-y-0 right-0 w-[2px] bg-[#52ADC9] origin-bottom scale-y-0 transition-transform delay-200 duration-200 group-hover:scale-y-100"></div>
                  <div className="absolute inset-x-0 top-0 h-[2px] bg-[#52ADC9] origin-right scale-x-0 transition-transform delay-400 duration-200 group-hover:scale-x-100"></div>
                  <div className="absolute inset-y-0 left-0 w-[2px] bg-[#52ADC9] origin-top scale-y-0 transition-transform delay-600 duration-200 group-hover:scale-y-100"></div>
                </div>
              </button>

              {/* Smaller Footer Text */}
              <p className="absolute bottom-6 text-gray-500 text-xs md:text-sm tracking-wide animate-fade duration-1000 drop-shadow-md">
                <span
                  className="relative opacity-50 text-[10px] md:text-xs hover:cursor-pointer transition-colors duration-300 after:content-[''] after:absolute after:left-0 after:bottom-[-1px] after:w-0 after:h-[1px] after:bg-gray-500 after:transition-all after:duration-300 hover:after:w-full"
                  onClick={() =>
                    window.open(
                      "https://paint-gopher-011.notion.site/Pumpkin-Blog-1b5f7ced31828035b2abd47417115841",
                      "_blank"
                    )
                  }
                >
                  Version 0.0.39
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
